<template>
  <v-container id="LoginView" class="justify-center" fill-height fluid>
    <v-card outlined class="pa-6 text-center" rounded="xl">
      <img src="@/assets/nixt_logo.svg" width="64px" />
      <v-card-title class="justify-center pt-0">
        <b>NiXT ADMIN CONSOLE</b>
      </v-card-title>
      <v-card-subtitle class="justify-center">
        - Please login with your Banpu account -
      </v-card-subtitle>
      <v-card-text class="justify-center pt-4">
        <v-btn rounded color="primary" @click.prevent="loginButtonClicked">
          Sign In with Banpu Account
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginView",
  methods: {
    ...mapActions({
      redirectToActiveDirectoryUrl: "redirectToActiveDirectoryUrl",
    }),
    async loginButtonClicked() {
      await this.redirectToActiveDirectoryUrl();
    },
  },
};
</script>
